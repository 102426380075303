import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import { useRef } from "react";
import useConfirm from "../hook/confirm";
import { useTranslation } from "react-i18next";

export const ConfirmModal = () => {
  const { t } = useTranslation();
  const { onConfirm, onCancel, state } = useConfirm();
  const cancelRef = useRef(null);
  return (
    <AlertDialog isOpen={state.show} leastDestructiveRef={cancelRef} onClose={onCancel}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {t("Uwaga!")}
          </AlertDialogHeader>

          <AlertDialogBody>{state.text}</AlertDialogBody>

          <AlertDialogFooter>
            <Button data-cy={"cancel"} ref={cancelRef} onClick={onCancel}>
              {t("Anuluj")}
            </Button>
            <Button data-cy={"confirm"} colorScheme="red" onClick={onConfirm} ml={3}>
              {t("Tak")}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

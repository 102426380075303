import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultStockQuery } from "../services/stockApi";
import { InputGetStockByQuery } from "../interface/api";
import { RootState } from "../utils/store";
import { IDateRangeData } from "../components/DateRange";

export type FilterInputs = Partial<InputGetStockByQuery> & { addedDate: IDateRangeData; removedDate: IDateRangeData };

interface Pagination {
  pageSize: number;
  offset: number;
}

interface QueryState {
  filters: Partial<InputGetStockByQuery>;
}

const initialState: QueryState & Pagination = {
  filters: defaultStockQuery,
  pageSize: 50,
  offset: 0,
};

const slice = createSlice({
  name: "stokcQuery",
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<Partial<FilterInputs>>) => {
      delete action.payload.addedDate;
      delete action.payload.removedDate;
      state.filters = { ...defaultStockQuery, ...action.payload };
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setSortBy: (state, action: PayloadAction<{ sortBy?: string; sortDescending: boolean }>) => {
      state.filters.sortBy = action.payload.sortBy;
      state.filters.sortDescending = action.payload.sortDescending;
    },
    reset: (state) => {
      state.filters = initialState.filters;
      state.pageSize = initialState.pageSize;
      state.offset = initialState.offset;
    },
  },
});

export const selectFilters = (state: RootState) => state.stockQuery.filters;
export const paginationSelector = (state: RootState) => state.stockQuery;
export const { setFilters, setPageSize, setOffset, setSortBy, reset } = slice.actions;
export default slice.reducer;

import { RfidAction, RfidState, RfidTypes } from "../interface/rfid";

export const initialState: RfidState = {
  show: false,
  title: "",
  description: "",
};

export const reducer = (state = initialState, action: RfidAction) => {
  switch (action.type) {
    case RfidTypes.SHOW:
      return {
        show: true,
        title: action.payload.title,
        description: action.payload.description,
      };
    case RfidTypes.HIDE:
      return initialState;
  }
};

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Types, TypesPost } from "../interface";
import { InputWrapper } from "../components/Input";
import { validationRules } from "../utils/reactHookForm";
import { useAddTypesMutation, useUpdateTypeMutation } from "../services/typesApi";
import { AddEditElement } from "../components/AddEditElement";
import { successToast } from "../utils/toast";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  icon?: boolean;
  button?: boolean;
  types?: Types;
}

export const AddEditTypes = ({ types, icon = false, button = false }: Props) => {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [addTypes, { isLoading }] = useAddTypesMutation();
  const [updateTypes, { isLoading: isUpdateLoading }] = useUpdateTypeMutation();

  const memoTypes = useMemo(() => types, [types]);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<TypesPost>({
    defaultValues: types ? memoTypes : { name: "" },
  });

  useEffect(() => {
    reset(types);
  }, [reset, types]);

  const onSubmit: SubmitHandler<TypesPost> = async (values: TypesPost) => {
    if (types) {
      updateTypes({ id: types.id, ...values })
        .unwrap()
        .then(() => {
          successToast(t("Zaktualizowałeś typ"));
          onClose();
          reset();
        });
    } else {
      await addTypes(values)
        .unwrap()
        .then(() => {
          successToast(t("Dodałeś nowy typ"));
          onClose();
          reset();
        })
        .catch(() => {
          successToast(t("Nie udało się dodać nowego typu"));
          reset();
        });
    }
  };

  const onCloseModal = () => {
    reset();
    onClose();
  };
  return (
    <>
      <AddEditElement
        testIdButton={`add-type-button`}
        testIdIcon={`add-type-icon`}
        onOpen={onOpen}
        icon={icon}
        buttonLabel={button ? t("Dodaj nowy typ") : undefined}
      />
      <Modal isOpen={isOpen} onClose={onCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>{types ? t("Edytuj typ") : t("Dodaj typ")}</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <InputWrapper
                data-cy={"name-input"}
                autoFocus={true}
                {...register("name", {
                  required: validationRules.required,
                  minLength: validationRules.minLength(3),
                  maxLength: validationRules.maxLength(15),
                })}
                label={t("Nazwa")}
                errors={errors}
              />
              <InputWrapper
                min={1}
                rightaddon={"g/\u33A1"}
                type={"number"}
                data-cy={"grammage-input"}
                {...register("grammage", {
                  required: validationRules.required,
                  min: validationRules.min(1),
                })}
                label={t("Gramatura")}
                errors={errors}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                data-cy={"send-type-form"}
                colorScheme="blue"
                mr={3}
                type="submit"
                isLoading={isLoading || isUpdateLoading}
              >
                {t("Zapisz")}
              </Button>
              <Button data-cy={"reset-type-form"} onClick={onCloseModal}>
                {t("Anuluj")}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

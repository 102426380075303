import { Box, Container, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { Fragment } from "react";
import { Links } from "./AppBar";
import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import useRoles from "../hook/useRoles";
import { useTranslation } from "react-i18next";

const Logo = () => <img src={"/assets/package.svg"} alt={"logo"} />;

export default function Footer() {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const { userHasRoles } = useRoles();
  return (
    <Box bg={useColorModeValue("gray.50", "gray.900")} color={useColorModeValue("gray.700", "gray.200")}>
      <Container as={Stack} maxW={"6xl"} py={4} spacing={4} justify={"center"} align={"center"}>
        <Logo />
        {keycloak.authenticated && (
          <Stack direction={"row"} spacing={6}>
            {Links.map((link, key) => {
              if (userHasRoles(link.roles)) {
                return (
                  <Fragment key={key}>
                    <Link to={link.location}>{link.label}</Link>
                  </Fragment>
                );
              } else {
                return null;
              }
            })}
            <Link to={"#"}>{t("Kontakt")}</Link>
          </Stack>
        )}
      </Container>
      <Box borderTopWidth={1} borderStyle={"solid"} borderColor={useColorModeValue("gray.200", "gray.700")}>
        <Container
          as={Stack}
          maxW={"6xl"}
          py={4}
          direction={{ base: "column", md: "row" }}
          spacing={4}
          justify={{ base: "center", md: "space-between" }}
          align={{ base: "center", md: "center" }}
        >
          <Text>© {new Date().getFullYear()} KuliktSoft. All rights reserved</Text>
        </Container>
      </Box>
    </Box>
  );
}

import { useContext } from "react";
import ConfirmContext from "../context/Confirm.context";
import { ConfirmTypes } from "../interface/confirm";

let resolveCallback: (value: unknown) => void;

function useConfirm() {
  const { state, dispatch } = useContext(ConfirmContext);
  const onConfirm = () => {
    closeConfirm();
    resolveCallback(true);
  };

  const onCancel = () => {
    closeConfirm();
    resolveCallback(false);
  };
  const confirm = (text: string) => {
    dispatch({
      type: ConfirmTypes.SHOW_CONFIRM,
      payload: {
        text,
        show: true,
      },
    });
    return new Promise((res) => {
      resolveCallback = res;
    });
  };

  const closeConfirm = () => {
    dispatch({
      type: ConfirmTypes.HIDE_CONFIRM,
    });
  };

  return { confirm, onConfirm, onCancel, state };
}

export default useConfirm;

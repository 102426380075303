import React, { FC, Fragment, useCallback } from "react";
import { Button, chakra, Flex, Icon, Menu, MenuButton, MenuItem, MenuList, useColorModeValue } from "@chakra-ui/react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { ArrowLeftIcon, ArrowRightIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { DOTS, DotsType } from "../hook/usePagination";
import { useAppDispatch, useAppSelector } from "../utils/store";
import { paginationSelector, setOffset, setPageSize } from "../slice/filtersSlice";

interface IPagButton {
  disabled?: boolean;
  active?: boolean;
  onClick?: () => void;
}

const PagButton: FC<IPagButton> = (props) => {
  const activeStyle = {
    bg: useColorModeValue("teal.600", "teal.500"),
    color: useColorModeValue("white", "gray.200"),
  };

  const inactiveStyle = {
    bg: useColorModeValue("white", "gray.800"),
    color: useColorModeValue("gray.700", "gray.200"),
  };

  return (
    <chakra.button
      mx={1}
      px={4}
      py={2}
      rounded="md"
      bg={props.active ? activeStyle.bg : inactiveStyle.bg}
      color={props.active ? activeStyle.color : inactiveStyle.color}
      opacity={props.disabled ? 0.6 : 1}
      _hover={!props.disabled ? activeStyle : undefined}
      cursor={props.disabled ? "not-allowed" : undefined}
      onClick={props.onClick}
    >
      {props.children}
    </chakra.button>
  );
};

interface MButtonProps extends IPagButton {
  left?: boolean;
}

const MButton = (props: MButtonProps) => {
  const activeStyle = {
    bg: useColorModeValue("teal.600", "teal.500"),
    color: useColorModeValue("white", "gray.200"),
  };

  const inactiveStyle = {
    bg: useColorModeValue("white", "gray.800"),
    color: useColorModeValue("gray.700", "gray.200"),
  };

  const DoubleArrow = props.left ? ArrowLeftIcon : ArrowRightIcon;
  const color1 = useColorModeValue("teal.800", "teal.700");

  return (
    <chakra.button
      mx={1}
      px={4}
      py={2}
      rounded="md"
      bg={props.active ? activeStyle.bg : inactiveStyle.bg}
      color={props.active ? activeStyle.color : inactiveStyle.color}
      opacity={props.disabled ? 0.6 : 1}
      _hover={!props.disabled ? activeStyle : undefined}
      cursor={props.disabled ? "not-allowed" : undefined}
      onClick={props.onClick}
    >
      <Icon as={DoubleArrow} boxSize={3} cursor="pointer" color={color1} />
    </chakra.button>
  );
};

interface PaginationProps {
  pages: Array<number | DotsType>;
  currentPage: number;
  size: number;
}

const Pagination = ({ pages, currentPage, size }: PaginationProps) => {
  const dispatch = useAppDispatch();
  const pagination = useAppSelector(paginationSelector);
  const maxPage = Math.ceil(size / pagination.pageSize);

  const handleMaxResults = useCallback(
    (e: any) => {
      dispatch(setOffset(0));
      dispatch(setPageSize(e.target.value));
    },
    [dispatch]
  );

  const setPage = useCallback(
    (page: number) => {
      const offset = (page - 1) * pagination.pageSize;
      if (offset < 0) return;
      if (maxPage < page) return;
      dispatch(setOffset(offset));
    },
    [dispatch, maxPage, pagination.pageSize]
  );

  return (
    <Flex bg={useColorModeValue("#F9FAFB", "gray.600")} p={3} w="full" alignItems="flex-end" justifyContent="flex-end">
      <Flex>
        <MButton disabled={currentPage === 1} left onClick={() => setPage(1)} />
        <PagButton disabled={currentPage === 1} onClick={() => setPage(currentPage - 1)}>
          <Icon as={IoIosArrowBack} color={useColorModeValue("gray.700", "gray.200")} boxSize={4} />
        </PagButton>

        {pages.map((page, i) =>
          page === DOTS ? (
            <Fragment key={i}>...</Fragment>
          ) : (
            <PagButton key={i} active={currentPage === page} onClick={() => setPage(page)}>
              {page}
            </PagButton>
          )
        )}

        <PagButton onClick={() => setPage(currentPage + 1)} disabled={maxPage === currentPage}>
          <Icon as={IoIosArrowForward} color={useColorModeValue("gray.700", "gray.200")} boxSize={4} />
        </PagButton>

        <MButton disabled={maxPage === currentPage} onClick={() => setPage(maxPage)} />
        <Menu>
          <MenuButton ml={1} as={Button} rightIcon={<ChevronDownIcon />}>
            {pagination.pageSize}
          </MenuButton>
          <MenuList>
            <MenuItem onClick={handleMaxResults} value={20}>
              20
            </MenuItem>
            <MenuItem onClick={handleMaxResults} value={30}>
              30
            </MenuItem>
            <MenuItem onClick={handleMaxResults} value={40}>
              40
            </MenuItem>
            <MenuItem onClick={handleMaxResults} value={50}>
              50
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};

export default Pagination;

export const blobToFile = (data: any) => {
  const file = new Blob([data], { type: "application/pdf" });
  return window.URL.createObjectURL(file);
};

export const downloadPdf = (pdf: any, name: string) => {
  const link = document.createElement("a");
  link.href = pdf;
  link.download = `${name}.pdf`;
  link.click();
};

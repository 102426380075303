import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Box, Button, Divider, Flex, FormControl, FormLabel, Grid, HStack, Stack } from "@chakra-ui/react";
import { WeightInput } from "../form/WeightInput";
import { WidthInput } from "../form/WidthInput";
import { LocationInput } from "../form/LocationInput";
import { NumberSt } from "../form/NumberSt";
import { StringSt } from "../form/StringSt";
import { ShowInput } from "../form/ShowInput";
import { useAppDispatch } from "../utils/store";
import { DateRangeInput } from "../components/DateRange";
import { FilterInputs, reset, setFilters, setOffset } from "../slice/filtersSlice";
import _ from "lodash";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  AutoCompleteTag,
} from "@choc-ui/chakra-autocomplete";
import { Types } from "../interface";
import { useGetAllQuery } from "../services/typesApi";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { infoToast } from "../utils/toast";

export const StockFilter = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const methods = useForm<FilterInputs>();
  const { data = [] } = useGetAllQuery();

  const resetForm = useCallback(() => {
    methods.reset();
    dispatch(reset());
    infoToast("Zresetowano");
  }, [methods, dispatch]);

  const onSubmit: SubmitHandler<FilterInputs> = async (values: FilterInputs) => {
    const [addedAfter, addedBefore] = values.addedDate || [null, null];
    const [removedAfter, removedBefore] = values.removedDate || [null, null];

    dispatch(
      setFilters(
        _.pickBy(
          {
            ...values,
            addedBefore: addedBefore?.toISOString(),
            addedAfter: addedAfter?.toISOString(),
            removedBefore: removedBefore?.toISOString(),
            removedAfter: removedAfter?.toISOString(),
            typesId: values.typesId?.map((id) => {
              const res = data.find((type) => type.name === id);
              return res?.id;
            }),
          },
          _.identity
        )
      )
    );
    dispatch(setOffset(0));
    infoToast("Zaktualizowano");
  };

  return (
    <>
      <Flex justifyContent={"flex-end"}>
        {/*FIXME https://github.com/anubra266/choc-autocomplete/issues/140*/}
        {/*<HStack>*/}
        {/*  <Button m={3} onClick={onToggle}>*/}
        {/*    Filtruj wyniki*/}
        {/*  </Button>*/}
        {/*</HStack>*/}
      </Flex>
      {/*<Collapse in={isOpen} animateOpacity>*/}
      <Stack ml={3} mr={3}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid templateColumns="repeat(4, 1fr)" gap={6}>
              <HStack pb={4}>
                <WeightInput />
                <NumberSt name={"weightST"} placeholder={t("Wybierz warunek")} />
              </HStack>
              <HStack pb={4}>
                <WidthInput />
                <NumberSt name={"widthST"} placeholder={t("Wybierz warunek")} />
              </HStack>
              <HStack pb={4}>
                <LocationInput />
                <StringSt name={"locationST"} placeholder={t("Wybierz warunek")} />
              </HStack>
              <Controller
                name={"typesId"}
                control={methods.control}
                render={({ field }) => {
                  return (
                    <FormControl>
                      <FormLabel>Typy:</FormLabel>
                      <AutoComplete
                        openOnFocus
                        multiple
                        onChange={(vals) => {
                          field.onChange(vals);
                        }}
                      >
                        <AutoCompleteInput placeholder={`${t("Wybierz typy")}...`}>
                          {({ tags }) =>
                            tags.map((tag, tid) => {
                              const label = data.find((el: Types) => el.name === tag.label)?.name ?? tag.label;
                              return <AutoCompleteTag key={tid} label={label} onRemove={tag.onRemove} />;
                            })
                          }
                        </AutoCompleteInput>
                        <AutoCompleteList>
                          {data.map((type: Types) => (
                            <AutoCompleteItem
                              key={type.id}
                              value={type}
                              getValue={(val: Types) => val.name}
                              textTransform="capitalize"
                              _selected={{ bg: "whiteAlpha.50" }}
                              _focus={{ bg: "whiteAlpha.100" }}
                            >
                              {type.name}
                            </AutoCompleteItem>
                          ))}
                        </AutoCompleteList>
                      </AutoComplete>
                    </FormControl>
                  );
                }}
              />
              <ShowInput name={"show"} placeholder={t("Status")} />
              <DateRangeInput name={"addedDate"} label={t("Data dodania(zakres)")} />
              <DateRangeInput name={"removedDate"} label={t("Data usunięcia(zakres)")} />
            </Grid>
            <Box as={Flex} justify={"flex-end"} p={2}>
              <HStack spacing="24px">
                <Button data-cy={"cancel"} onClick={resetForm} type="reset">
                  {t("Anuluj")}
                </Button>
                <Button data-cy="save" colorScheme="teal" mr={3} type="submit">
                  {t("Zapisz")}
                </Button>
              </HStack>
            </Box>
          </form>
        </FormProvider>
      </Stack>
      <Divider />
      {/*</Collapse>*/}
    </>
  );
};

import { IPeon } from "../interface";
import { memo } from "react";

interface Props {
  peon?: IPeon;
}

export const Peon = memo((props: Props) => {
  if (!!props.peon) {
    return (
      <span>
        {props.peon.name} {props.peon.surname}
      </span>
    );
  } else {
    return <span />;
  }
});

import { IconButton as IconButtonCH, IconButtonProps, Tooltip } from "@chakra-ui/react";

interface Props extends Omit<IconButtonProps, "aria-label"> {
  tooltip: string;
  testId: string;
}

export const IconButton = ({ testId, tooltip, ...rest }: Props) => {
  return (
    <Tooltip label={tooltip}>
      <IconButtonCH data-cy={`${testId}-icon-button`} {...rest} aria-label={tooltip} />
    </Tooltip>
  );
};

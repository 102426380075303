import DatePicker from "react-datepicker";
import { FormControl, FormLabel } from "@chakra-ui/react";
import { Controller, useFormContext, UseFormReturn } from "react-hook-form";
import { Name } from "../../interface";

import "react-datepicker/dist/react-datepicker.css";
import "./date-picker.css";

export type IDateRangeData = [Date | null, Date | null];

interface Props {
  label: string;
  selected: IDateRangeData;
  onChange: (update: IDateRangeData) => void;
}

export const DateRange = ({ label, selected, onChange }: Props) => {
  const [startDate, endDate] = selected ?? [null, null];
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <DatePicker selectsRange={true} startDate={startDate} endDate={endDate} onChange={onChange} isClearable={true} />
    </FormControl>
  );
};

const DateRangeInputBase = ({ control, name, label }: UseFormReturn & Name & { label: string }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <DateRange
            label={label}
            selected={field.value}
            onChange={(update: IDateRangeData) => {
              field.onChange(update);
            }}
          />
        );
      }}
    />
  );
};

export const DateRangeInput = ({ name, label }: Name & { label: string }) => {
  const methods = useFormContext();
  return <DateRangeInputBase {...methods} name={name} label={label} />;
};

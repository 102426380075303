import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import { NavLink } from "./NavLink";
import { Fragment } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { RootState, useAppSelector } from "../utils/store";
import useRoles from "../hook/useRoles";
import { ADMIN_ACCESS, FULL_ACCESS, Roles } from "../utils/roles";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface ILink {
  label: string;
  location: string;
  roles: Array<Roles>;
}

export const Links: Array<ILink> = [
  { label: "Strona główna", location: "/", roles: FULL_ACCESS },
  { label: "Stocki", location: "/stock", roles: FULL_ACCESS },
  { label: "Użytkownicy", location: "/employee", roles: ADMIN_ACCESS },
  { label: "Typ folii", location: "/types", roles: ADMIN_ACCESS },
];

export default function AppBar() {
  const { t } = useTranslation();
  const { userHasRoles } = useRoles();
  let navigate = useNavigate();
  const user = useAppSelector((state: RootState) => state.auth.user);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { keycloak } = useKeycloak();
  const name =
    user?.firstName && user?.lastName ? `${user.firstName} ${user.lastName}` : user?.username ?? t("Nieznajomy");
  return (
    <>
      <Box bg={useColorModeValue("teal", "gray.900")} px={4}>
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={"center"}>
            <Box>
              <img src={"/assets/package.svg"} alt={"logo"} />
            </Box>
            <HStack as={"nav"} spacing={4} display={{ base: "none", md: "flex" }}>
              {Links.map((link, key) => {
                if (userHasRoles(link.roles)) {
                  return <NavLink key={key} {...link} />;
                } else {
                  return null;
                }
              })}
            </HStack>
          </HStack>
          <Flex alignItems={"center"}>
            {keycloak.authenticated && (
              <Menu>
                <MenuButton as={Button} rounded={"full"} variant={"link"} cursor={"pointer"} minW={0}>
                  <Avatar size={"sm"} name={name} />
                </MenuButton>
                <MenuList>
                  {/*<MenuDivider />*/}
                  <MenuItem
                    onClick={() => {
                      navigate("../", { replace: true });
                      keycloak.logout();
                    }}
                  >
                    Wyloguj
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </Flex>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: "none" }}>
            <Stack as={"nav"} spacing={4}>
              {Links.map((link, key) => (
                <Fragment key={key}>
                  <NavLink {...link} />
                </Fragment>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>
    </>
  );
}

import { Route, Routes } from "react-router-dom";
import Home from "../page/Home";
import NoLoginPage from "../page/NoLoginPage";
import { PrivateRoute } from "./PrivateRoute";
import { UsersTable } from "../page/UsersTable";
import { TypesTable } from "../page/TypesTable";
import { StocksTable } from "../page/StocksTable";
import NotFound from "../components/NotFound";
import React from "react";
import { useKeycloak } from "@react-keycloak/web";
import useRoles from "../hook/useRoles";

export const Router = () => {
  const { keycloak } = useKeycloak();
  const { isAdmin } = useRoles();
  return (
    <Routes>
      <Route path={"/"} element={keycloak.authenticated ? <Home /> : <NoLoginPage />} />
      {isAdmin && (
        <Route
          path="/employee"
          element={
            <PrivateRoute>
              <UsersTable />
            </PrivateRoute>
          }
        />
      )}
      {isAdmin && (
        <Route
          path="/types"
          element={
            <PrivateRoute>
              <TypesTable />
            </PrivateRoute>
          }
        />
      )}
      <Route
        path="/stock"
        element={
          <PrivateRoute>
            <StocksTable />
          </PrivateRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

import { Box, Button, Container, Heading, Stack, Text } from "@chakra-ui/react";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";

export default function NoLoginPage() {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();
  const login = () => keycloak.login();

  return (
    <Container maxW={"3xl"}>
      <Stack as={Box} textAlign={"center"} spacing={{ base: 8, md: 14 }} py={{ base: 20, md: 36 }}>
        <Heading fontWeight={600} fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }} lineHeight={"110%"}>
          {t("Zarządzaj procesami biznesowymi")} <br />
          <Text as={"span"} color={"teal.400"}>
            {t("z naszym oprogramowaniem")}
          </Text>
        </Heading>
        <Text color={"gray.500"}>
          {t(
            "Dodawaj i usuwaj produkty. Zarządzaj pracownikami, dzięki tokenom RFID, zadbaj o swój proces magazynowy razem z nami."
          )}
        </Text>
        <Stack direction={"column"} spacing={3} align={"center"} alignSelf={"center"} position={"relative"}>
          <Button
            data-cy={"login-button"}
            onClick={login}
            colorScheme={"teal"}
            bg={"teal.400"}
            rounded={"full"}
            px={6}
            _hover={{
              bg: "teal.500",
            }}
          >
            {t("Zaloguj się")}
          </Button>
          <Button data-cy={"support-button"} variant={"link"} colorScheme={"teal"} size={"sm"}>
            {t("Support")}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
}

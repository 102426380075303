import { useFormContext, UseFormReturn } from "react-hook-form";
import { FormControl, FormLabel, Select, SelectProps } from "@chakra-ui/react";
import { showType } from "../utils/filter";
import { Name } from "../interface";
import { useTranslation } from "react-i18next";

export const ShowInputBase = ({ register, name, ...rest }: UseFormReturn & Name & SelectProps) => {
  const { t } = useTranslation();
  return (
    <FormControl>
      <FormLabel>{rest.placeholder}</FormLabel>
      <Select placeholder={t("Wybierz")} {...register(name)}>
        {Object.entries(showType).map(([key, value]) => (
          <option key={key} value={key}>
            {value}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

export const ShowInput = (props: SelectProps & Name) => {
  const methods = useFormContext();

  return <ShowInputBase {...methods} {...props} />;
};

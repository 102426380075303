import { validationRules } from "../utils/reactHookForm";
import { Controller, useFormContext, UseFormReturn } from "react-hook-form";
import { InputProps } from "@chakra-ui/input/dist/declarations/src/input";
import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList } from "@choc-ui/chakra-autocomplete";
import { Types } from "../interface";
import { useGetAllQuery } from "../services/typesApi";
import { useTranslation } from "react-i18next";

type IInput = Partial<InputProps>;

const TypeInputBase = ({
  control,
  isDisabled,
  formState: { errors },
  defaultValue,
  isRequired,
}: UseFormReturn & IInput) => {
  const { t } = useTranslation();
  const { data = [] } = useGetAllQuery();
  return (
    <Controller
      name="typeId"
      control={control}
      rules={{
        required: isRequired ? validationRules.required : false,
      }}
      render={({ field }) => {
        return (
          <FormControl isInvalid={!!errors.typeId} isDisabled={isDisabled}>
            <FormLabel>Typ:</FormLabel>
            <AutoComplete
              openOnFocus
              listAllValuesOnFocus
              defaultValue={defaultValue ?? ""}
              onSelectOption={(params) => {
                field.onChange(params.item.value);
              }}
              value={field.value}
            >
              <AutoCompleteInput
                data-cy={"type-input"}
                ref={field.ref}
                placeholder={t("Wybierz typ")}
                autoFocus={false}
              />
              <AutoCompleteList>
                {data.map((type: Types, oid) => (
                  <AutoCompleteItem
                    data-cy={`item-${type.id}`}
                    key={`option-${oid}`}
                    value={`${type.id}`}
                    label={type.name}
                  >
                    {type.name}
                  </AutoCompleteItem>
                ))}
              </AutoCompleteList>
            </AutoComplete>
            <FormErrorMessage>{errors?.typeId?.message}</FormErrorMessage>
          </FormControl>
        );
      }}
    />
  );
};

export const TypeInput = (props: IInput) => {
  const methods = useFormContext();

  return <TypeInputBase {...methods} {...props} />;
};

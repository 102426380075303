import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import * as _ from "lodash";
import { ENDPOINTS } from "../config";
import { Employee, EmployeeList, EmployeePost, EmployeePut, Id } from "../interface";
import { prepareHeaders, providesList } from "../utils/reudx";

const tagName = "Employee";

export const employeeApi = createApi({
  reducerPath: "employeeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: ENDPOINTS.EMPLOYEE,
    prepareHeaders: prepareHeaders,
  }),
  tagTypes: [tagName],
  endpoints: (builder) => ({
    getAll: builder.query<EmployeeList, void>({
      query: () => ({
        url: "/getAll",
        method: "GET",
      }),
      providesTags: (result) => providesList(result, tagName),
    }),
    getById: builder.query<Employee, Id>({
      query: (id) => "/get/" + id,
      providesTags: [tagName],
    }),
    deleteById: builder.mutation<void, Id>({
      query: (id) => ({
        url: "/delete/" + id,
        method: "DELETE",
      }),
      invalidatesTags: [tagName],
    }),
    addEmployee: builder.mutation<Employee, EmployeePost>({
      query: (body) => ({
        url: `/add`,
        method: "POST", // When performing a mutation, you typically use a method of PATCH/PUT/POST/DELETE for REST endpoints
        body: body, // fetchBaseQuery automatically adds `content-type: application/json` to the Headers and calls `JSON.stringify(patch)`
      }),
      async onQueryStarted(res, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            employeeApi.util.updateQueryData("getAll", undefined, (draft) => {
              draft.push(data);
            })
          );
        } catch {}
      },
    }),
    updateEmployee: builder.mutation<Employee, EmployeePut>({
      query: (body) => ({
        url: `/update`,
        method: "PUT",
        body: _.pickBy(body, _.identity),
      }),
      onQueryStarted: updateUserDataInList,
    }),
    updateEmployeeStatus: builder.mutation<Employee, { id: Id; status: boolean }>({
      query: (body) => ({
        url: `${body.id}/active/${body.status}`,
        method: "PUT",
      }),
      onQueryStarted: updateUserDataInList,
    }),
    getEmployeeByRfid: builder.mutation<Employee, { rfid: string }>({
      query: (body) => ({
        url: `/getByCode/${body.rfid}`,
        method: "GET",
      }),
    }),
  }),
});

async function updateUserDataInList(
  { id, ...patch }: { [p: string]: any; id: any },
  { dispatch, queryFulfilled }: { dispatch: any; queryFulfilled: any }
) {
  try {
    const { data } = await queryFulfilled;
    dispatch(
      employeeApi.util.updateQueryData("getAll", undefined, (draft) => {
        draft[draft.findIndex((post) => post.id === id)] = data;
      })
    );
  } catch {}
}

// Export hooks for usage in functional components
export const {
  useDeleteByIdMutation,
  useAddEmployeeMutation,
  useGetByIdQuery,
  useGetAllQuery,
  useUpdateEmployeeMutation,
  useUpdateEmployeeStatusMutation,
  useGetEmployeeByRfidMutation,
} = employeeApi;

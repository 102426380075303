import { useCallback, useRef, useState } from "react";
import { Id, IStock } from "../interface";
import { IconButton } from "../components/IconButton";
import { DeleteIcon } from "@chakra-ui/icons";
import { HStack, Icon } from "@chakra-ui/react";
import useConfirm from "../hook/confirm";
import { useMarkAsRemovedMutation } from "../services/stockApi";
import { errorToast, successToast } from "../utils/toast";
import { BiNote } from "react-icons/bi";
import { GrDocumentPdf } from "react-icons/gr";
import { MdEditLocationAlt } from "react-icons/md";
import { AddEditRef, AddEditStockModal } from "./AddEditStockModal";
import useRoles from "../hook/useRoles";
import { ADMIN_ACCESS } from "../utils/roles";
import useRfid from "../hook/useRfid";
import client from "../utils/client";
import { ENDPOINTS } from "../config";
import { blobToFile, downloadPdf } from "../utils/blob";
import { useTranslation } from "react-i18next";

interface Props {
  stock: IStock;
}

export const StocksActions = ({ stock }: Props) => {
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const { userHasRoles } = useRoles();
  const { getRfid } = useRfid();
  const addEditRef = useRef<AddEditRef>(null);
  const [removeDelete, { isLoading }] = useMarkAsRemovedMutation();
  const [isDownloading, setIsDownloading] = useState(false);

  const removeAction = useCallback(
    (id: Id, code?: string) => {
      removeDelete({ id, code })
        .unwrap()
        .then(() => successToast(t("Usunięto")))
        .catch(() => errorToast(t("Nie udało się usunąć")));
    },
    [t, removeDelete]
  );

  const remove = useCallback(() => {
    confirm(t("Czy na pewno chcesz usunąć?")).then((isConfirmed) => {
      if (isConfirmed) {
        if (userHasRoles(ADMIN_ACCESS)) {
          removeAction(stock.id);
        } else {
          getRfid(t("Potwierdź, że chcesz skasować ten stock, swoim tokenem RFID ")).then((rfid: string) => {
            removeAction(stock.id, rfid);
          });
        }
      }
    });
  }, [t, confirm, userHasRoles, removeAction, stock.id, getRfid]);

  const editNote = useCallback(() => {
    addEditRef?.current?.editNote();
  }, [addEditRef]);

  const editLocation = useCallback(() => {
    addEditRef?.current?.editLocation();
  }, [addEditRef]);

  const getPdf = useCallback(async () => {
    setIsDownloading(true);
    try {
      const res = await client(`${ENDPOINTS.REPORT}/getLabel/${stock.id}`, {
        responseType: "blob",
      });
      const data = blobToFile(res.data);
      downloadPdf(data, `label_${stock.id}.pdf`);
      successToast(t("Pobrano"));
    } catch (e) {
      errorToast(t("Nie udało się pobrać pliku"));
    } finally {
      setIsDownloading(false);
    }
  }, [t, stock]);

  return (
    <HStack spacing={3}>
      <AddEditStockModal icon stock={stock} ref={addEditRef} />
      <IconButton
        testId={"edit-location"}
        isLoading={isLoading}
        icon={<Icon as={MdEditLocationAlt} color={"blue.500"} />}
        tooltip={t("Zaktualizuj lokalizacje")}
        onClick={editLocation}
      />
      <IconButton
        testId={"edit-note"}
        isLoading={isLoading}
        icon={<Icon as={BiNote} color={"blue.500"} />}
        tooltip={t("Zaktualizuj opis")}
        onClick={editNote}
      />
      {!stock.removed && (
        <IconButton
          testId={"remove-stock"}
          isLoading={isLoading}
          icon={<DeleteIcon color={"red.500"} />}
          tooltip={t("Usuń")}
          onClick={remove}
        />
      )}
      <IconButton
        testId={"get-pdf"}
        tooltip={t("Wygeneruj PDF")}
        icon={<Icon as={GrDocumentPdf} />}
        onClick={getPdf}
        isLoading={isDownloading}
      />
    </HStack>
  );
};

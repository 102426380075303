import { useContext } from "react";
import RfidContext from "../context/Rfid.context";
import { RfidTypes } from "../interface/rfid";

let resolveCallback: (value: string) => any;
let rejectCallback: (reason: unknown) => void;

export default function useRfid() {
  const { state, dispatch } = useContext(RfidContext);

  const onOk = (rfid: string) => {
    resolveCallback(rfid);
  };

  const onError = (error: Error) => {
    rejectCallback(error);
  };

  const onCancel = () => {
    onClose();
    rejectCallback(new Error("Close modal by user"));
  };

  const onClose = () => {
    dispatch({
      type: RfidTypes.HIDE,
    });
  };

  const getRfid = (description: string) => {
    dispatch({
      type: RfidTypes.SHOW,
      payload: {
        show: true,
        title: "Zwerefikuj się swoim tokenem RFID",
        description,
      },
    });
    return new Promise<string>((resolve, reject) => {
      resolveCallback = resolve;
      rejectCallback = reject;
    });
  };

  return { onClose, getRfid, onOk, onCancel, state, onError };
}

import { Link as ChLink, useColorModeValue } from "@chakra-ui/react";
import { Link } from "react-router-dom";

interface Props {
  label: string;
  location: string;
}

export const NavLink = ({ label, location }: Props) => (
  <ChLink
    data-cy={`${location}-link`}
    px={2}
    py={1}
    rounded={"md"}
    background={"teal.100"}
    _hover={{
      textDecoration: "none",
      bg: useColorModeValue("teal.200", "teal.700"),
    }}
    to={location}
    as={Link}
  >
    {label}
  </ChLink>
);

import { useFormContext, UseFormReturn } from "react-hook-form";
import { FormControl, FormLabel, Select, SelectProps } from "@chakra-ui/react";
import { numberSt } from "../utils/filter";
import { Name } from "../interface";
import { useTranslation } from "react-i18next";

export const NumberStBase = ({ register, name, ...rest }: UseFormReturn & Name & SelectProps) => {
  const { t } = useTranslation();
  return (
    <FormControl>
      <FormLabel>{rest.placeholder}</FormLabel>
      <Select placeholder={t("Wybierz")} {...register(name)}>
        {Object.entries(numberSt).map(([key, value]) => (
          <option key={key} value={key}>
            {value}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

export const NumberSt = (props: SelectProps & Name) => {
  const methods = useFormContext();

  return <NumberStBase {...methods} {...props} />;
};

import { Center, Stack, Text } from "@chakra-ui/react";
import { LottieAnimation } from "../components/LottieAnimation";
import forklift from "../assets/forklift.json";

interface Props {
  text: string;
}

export const LoadingPage = ({ text }: Props) => (
  <Center h={"100vh"}>
    <Stack>
      <LottieAnimation animationData={forklift} />
      <Text fontSize="3xl" align={"center"} color={"teal"}>
        {text}
      </Text>
    </Stack>
  </Center>
);

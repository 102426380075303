import { ConfirmAction, ConfirmState, ConfirmTypes } from "../interface/confirm";

export const initialState: ConfirmState = {
  show: false,
  text: "",
};

export const reducer = (state = initialState, action: ConfirmAction) => {
  switch (action.type) {
    case ConfirmTypes.SHOW_CONFIRM:
      return {
        show: true,
        text: action.payload.text,
      };
    case ConfirmTypes.HIDE_CONFIRM:
      return initialState;
    default:
      return initialState;
  }
};

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Employee, EmployeePost } from "../interface";
import { InputWrapper } from "../components/Input";
import { useAddEmployeeMutation, useUpdateEmployeeMutation } from "../services/employeeApi";
import { validationRules } from "../utils/reactHookForm";
import { AddEditElement } from "../components/AddEditElement";
import { errorToast, successToast } from "../utils/toast";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  icon?: boolean;
  button?: boolean;
  employee?: Employee;
}

export const AddEditEmployeeModal = ({ employee, icon = false, button = false }: Props) => {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [addEmployee, { isLoading }] = useAddEmployeeMutation();
  const [updateEmployee, { isLoading: isUpdateLoading }] = useUpdateEmployeeMutation();

  const memoEmployee = useMemo(() => employee, [employee]);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<EmployeePost>({
    defaultValues: memoEmployee
      ? { ...employee }
      : {
          name: "",
          surname: "",
          code: "",
        },
  });

  useEffect(() => {
    reset(employee);
  }, [reset, employee]);

  const onSubmit: SubmitHandler<EmployeePost> = async (values: EmployeePost) => {
    if (employee) {
      await updateEmployee({ id: employee.id, ...values })
        .unwrap()
        .then(() => {
          successToast(t("Zaktualizowałeś pracownika"));
          onCloseModal();
        })
        .catch(() => {
          errorToast(t("Nie udało się zaktualizować pracownika"));
        });
    } else {
      await addEmployee(values)
        .unwrap()
        .then(() => {
          successToast(t("Dodałeś nowego pracownika"));
          onCloseModal();
        })
        .catch(() => errorToast(t("Nie udało się dodać nowego pracownika")));
    }
  };

  const onCloseModal = () => {
    reset();
    onClose();
  };

  return (
    <>
      <AddEditElement
        testIdButton={"add-employee-button"}
        testIdIcon={"add-employee-icon"}
        onOpen={onOpen}
        icon={icon}
        buttonLabel={button ? t("Dodaj pracownika") : undefined}
      />
      <Modal isOpen={isOpen} onClose={onCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>{employee ? t("Edytuj pracownika") : t("Dodaj pracownika")}</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <InputWrapper
                data-cy={"name-input"}
                autoFocus={true}
                {...register("name", {
                  required: validationRules.required,
                  minLength: validationRules.minLength(3),
                  maxLength: validationRules.maxLength(15),
                })}
                label={t("Imię")}
                errors={errors}
              />
              <InputWrapper
                data-cy={"surname-input"}
                {...register("surname", {
                  required: validationRules.required,
                  minLength: validationRules.minLength(3),
                  maxLength: validationRules.maxLength(15),
                })}
                label={t("Nazwisko")}
                errors={errors}
              />
              <InputWrapper
                data-cy={"code-input"}
                type={"number"}
                {...register("code", {
                  required: validationRules.required,
                  minLength: validationRules.minLength(10),
                  maxLength: validationRules.maxLength(10),
                })}
                label={t("Kod")}
                errors={errors}
                helpertext={t("Użyj czytnika RFID")}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                data-cy={"send-employee-form"}
                colorScheme="blue"
                mr={3}
                type="submit"
                isLoading={isLoading || isUpdateLoading}
              >
                {t("Zapisz")}
              </Button>
              <Button data-cy={"rest-employee-form"} onClick={onCloseModal}>
                {t("Anuluj")}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

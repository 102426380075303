import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { dispatch, store } from "./utils/store";
import keycloak from "./utils/keycloak";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { LoadingPage } from "./container/LoadingPage";
import { setTokenHeader } from "./utils/client";
import { setToken } from "./slice/authSlice";

import "./i18n";

const eventLogger = (event: unknown, error: unknown) => {
  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    console.log("onKeycloakEvent", event, error);
  }
};

const onTokens = ({ token }: any) => {
  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    console.log("token", token);
  }
  setTokenHeader(token);
  dispatch(setToken({ token }));
};

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="loading">
      <BrowserRouter>
        <ChakraProvider>
          <Provider store={store}>
            <ReactKeycloakProvider
              initOptions={{
                checkLoginIframe: false,
              }}
              authClient={keycloak}
              autoRefreshToken={true}
              LoadingComponent={<LoadingPage text={"Szukam magazynu"} />}
              onEvent={eventLogger}
              onTokens={onTokens}
            >
              <App />
            </ReactKeycloakProvider>
          </Provider>
        </ChakraProvider>
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

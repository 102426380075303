export const validationRules = {
  required: {
    value: true,
    message: "Pole wymagane",
  },
  minLength: (minLength: number) => ({
    value: minLength,
    message: `Minimalna długość to ${minLength} znaków`,
  }),
  maxLength: (maxLength: number) => ({
    value: maxLength,
    message: `Maksymalna długość to ${maxLength} znaków`,
  }),
  min: (min: number) => ({
    value: min,
    message: `Minimalna wartość to ${min}`,
  }),
  max: (max: number) => ({
    value: max,
    message: `Maksymalna wartość to ${max}`,
  }),
};

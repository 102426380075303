import { FC, memo } from "react";
import Lottie from "react-lottie";

export interface LottieAnimationProps {
  animationData: any;
}

export const LottieAnimation: FC<LottieAnimationProps> = memo(({ animationData }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return <Lottie options={defaultOptions} height={600} width={600} />;
});

import { validationRules } from "../utils/reactHookForm";
import { useFormContext, UseFormReturn } from "react-hook-form";
import { InputProps } from "@chakra-ui/input/dist/declarations/src/input";
import { FormControl, FormErrorMessage, FormLabel, Textarea } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

type IInput = Partial<InputProps>;

const NoteInputBase = ({ register, formState: { errors }, isDisabled }: UseFormReturn & IInput) => {
  const { t } = useTranslation();
  return (
    <FormControl isInvalid={!!errors.note} isDisabled={isDisabled}>
      <FormLabel htmlFor={"note"}>{t("Notatki")}</FormLabel>
      <Textarea
        data-cy={"note-textarea"}
        {...register("note", {
          minLength: validationRules.minLength(3),
          maxLength: validationRules.maxLength(500),
        })}
        placeholder={t("Notatki")}
        size="sm"
      />
      <FormErrorMessage>{errors.note?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const NoteInput = (props: IInput) => {
  const methods = useFormContext();

  return <NoteInputBase {...methods} {...props} />;
};

import { ActionMap } from "./index";

export interface RfidState {
  show: boolean;
  title: string;
  description: string;
}

export enum RfidTypes {
  SHOW = "SHOW",
  HIDE = "HIDE",
}

type RfidPayload = {
  [RfidTypes.SHOW]: RfidState;
  [RfidTypes.HIDE]: undefined;
};

export type RfidAction = ActionMap<RfidPayload>[keyof ActionMap<RfidPayload>];

import { DataTable } from "../components/Table";
import { useMemo } from "react";
import { useGetAllQuery } from "../services/employeeApi";
import { AddEditEmployeeModal } from "../container/AddEditEmployeeModal";
import { AsyncBox } from "../components/AsyncBox";
import { BooleanCell } from "../components/BoolenCell";
import { UserActions } from "../container/UserActions";
import { Cell } from "react-table";
import { Employee } from "../interface";
import { DateCell } from "../components/DateCell";
import { useTranslation } from "react-i18next";

export const UsersTable = () => {
  const { t } = useTranslation();
  const { isLoading, data = [], isError } = useGetAllQuery();
  const memoData = useMemo(() => data, [data]);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: t("Imię"),
        accessor: "name",
      },
      {
        Header: t("Nazwisko"),
        accessor: "surname",
      },
      {
        Header: t("Kod"),
        accessor: "code",
      },
      {
        Header: t("Aktywny"),
        accessor: "active",
        Cell: BooleanCell,
      },
      // {
      //     Header: "Usunięty",
      //     accessor: "deleted",
      //     Cell: BooleanCell,
      // },
      {
        Header: t("Utworzony"),
        accessor: "created",
        Cell: (cell: Cell<Employee>) => <DateCell dateIso={cell.row.original.created} />,
      },
      // {
      //     Header: "Usunięty",
      //     accessor: "removed",
      // },
      {
        Header: t("Akcje"),
        accessor: "actions",
        Cell: UserActions,
      },
    ],
    [t]
  );

  return (
    <AsyncBox isError={isError} isLoading={isLoading}>
      <AddEditEmployeeModal button />
      <DataTable data={memoData} columns={columns} />
    </AsyncBox>
  );
};

import React, { useCallback, useEffect, useState } from "react";
import AppBar from "./components/AppBar";
import { ConfirmContextProvider } from "./context/Confirm.context";
import { ConfirmModal } from "./components/ConfirmModal";
import { Box, CSSReset, Flex } from "@chakra-ui/react";
import { useKeycloak } from "@react-keycloak/web";
import { selectToken, setCredentials } from "./slice/authSlice";
import { useAppDispatch, useAppSelector } from "./utils/store";
import { LoadingPage } from "./container/LoadingPage";
import Footer from "./components/Footer";
import { Router } from "./container/Router";
import { RfidProvider } from "./context/Rfid.context";
import { RfidModal } from "./components/RfidModal";
import { setTokenHeader } from "./utils/client";
import { useTranslation } from "react-i18next";

function App() {
  const { t } = useTranslation();
  const { keycloak, initialized } = useKeycloak();
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);
  const [isAnimated, setIsAnimated] = useState(true);

  const loadUserData = useCallback(async () => {
    await keycloak.loadUserProfile();
    if (!!keycloak.token) {
      setTokenHeader(keycloak.token);
      dispatch(setCredentials({ token: keycloak.token, user: keycloak.profile }));
    }
    if (process.env.NODE_ENV === "development") {
      console.log("initialized", initialized);
      console.log("keycloak.token", keycloak.token);
      console.log("keycloak.idToken", keycloak.idToken);
      console.log("keycloak.refreshToken", keycloak.refreshToken);
      console.log("keycloak.realmAccess", keycloak.realmAccess);
      console.log("keycloak.resourceAccess", keycloak.resourceAccess);
      console.log("keycloak.profile", keycloak.profile);
    }
  }, [dispatch, keycloak, initialized]);

  useEffect(() => {
    if (keycloak.authenticated) {
      loadUserData();
    }
  }, [keycloak, loadUserData]);

  useEffect(() => {
    let timer = setTimeout(() => setIsAnimated(false), 2500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (keycloak.authenticated && token === null && !initialized) {
    return <LoadingPage text={t("Przygotowuje Twój magazyn")} />;
  }

  if (isAnimated) {
    return <LoadingPage text={t("Przygotowuje magazyn")} />;
  }

  return (
    <RfidProvider>
      <ConfirmContextProvider>
        <CSSReset />
        {keycloak.authenticated && <AppBar />}
        <Flex direction={"column"}>
          <Box minH={"100vh"}>
            <Router />
          </Box>
          <Box width={"100%"}>
            <Footer />
          </Box>
        </Flex>
        <RfidModal />
        <ConfirmModal />
      </ConfirmContextProvider>
    </RfidProvider>
  );
}

export default App;

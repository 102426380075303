import { createContext, Dispatch, FC, useReducer } from "react";
import { initialState, reducer } from "../reducer/Confirm.reducer";
import { ConfirmAction, ConfirmState } from "../interface/confirm";

const ConfirmContext = createContext<{
  state: ConfirmState;
  dispatch: Dispatch<ConfirmAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

export default ConfirmContext;

export const ConfirmContextProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <ConfirmContext.Provider value={{ state, dispatch }}>{children}</ConfirmContext.Provider>;
};

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from "../config";
import { Id, Types, TypesList, TypesPost, TypesPut } from "../interface";
import { prepareHeaders, providesList } from "../utils/reudx";
import * as _ from "lodash";

const tagName = "Types";

export const typesApi = createApi({
  reducerPath: "typesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: ENDPOINTS.TYPES,
    prepareHeaders: prepareHeaders,
  }),
  tagTypes: [tagName],
  endpoints: (builder) => ({
    getAll: builder.query<TypesList, void>({
      query: () => "/getAllActiveTypes",
      providesTags: (result) => providesList(result, tagName),
    }),
    getById: builder.query<Types, Id>({
      query: (id) => "/get/" + id,
      providesTags: [tagName],
    }),
    deleteById: builder.mutation<void, Id>({
      query: (id) => ({
        url: "/delete/" + id,
        method: "DELETE",
      }),
      invalidatesTags: [tagName],
    }),
    addTypes: builder.mutation<Types, TypesPost>({
      query: (body) => ({
        url: `/add`,
        method: "POST", // When performing a mutation, you typically use a method of PATCH/PUT/POST/DELETE for REST endpoints
        body: body, // fetchBaseQuery automatically adds `content-type: application/json` to the Headers and calls `JSON.stringify(patch)`
      }),
      async onQueryStarted(res, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            typesApi.util.updateQueryData("getAll", undefined, (draft) => {
              draft.push(data);
            })
          );
        } catch {}
      },
    }),
    updateType: builder.mutation<Types, TypesPut>({
      query: (body) => ({
        url: `/update`,
        method: "PUT",
        body: _.pickBy(body, _.identity),
      }),
      onQueryStarted: updateTypesDataInList,
    }),
    updateTypesStatus: builder.mutation<Types, { id: Id; status: boolean }>({
      query: (body) => ({
        url: `${body.id}/active/${body.status}`,
        method: "PUT",
      }),
      onQueryStarted: updateTypesDataInList,
    }),
  }),
});

async function updateTypesDataInList(
  { id, ...patch }: { [p: string]: any; id: any },
  { dispatch, queryFulfilled }: { dispatch: any; queryFulfilled: any }
) {
  try {
    const { data } = await queryFulfilled;
    dispatch(
      typesApi.util.updateQueryData("getAll", undefined, (draft) => {
        draft[draft.findIndex((post) => post.id === id)] = data;
      })
    );
  } catch {}
}

export const {
  useAddTypesMutation,
  useGetByIdQuery,
  useDeleteByIdMutation,
  useGetAllQuery,
  useUpdateTypeMutation,
  useUpdateTypesStatusMutation,
} = typesApi;

import { IconButton } from "./IconButton";
import { AddIcon, EditIcon } from "@chakra-ui/icons";
import { Button, Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface Props {
  testIdButton: string;
  testIdIcon: string;
  onOpen: () => void;
  icon: boolean;
  buttonLabel?: string;
}

export const AddEditElement = ({ icon, buttonLabel, onOpen, testIdButton, testIdIcon }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      {icon && (
        <IconButton testId={testIdIcon} icon={<EditIcon color={"blue.500"} />} tooltip={t("Edytuj")} onClick={onOpen} />
      )}
      {!!buttonLabel && (
        <Flex justifyContent={"flex-end"}>
          <Button data-cy={testIdButton} m={2} colorScheme="teal" leftIcon={<AddIcon />} onClick={onOpen}>
            {buttonLabel}
          </Button>
        </Flex>
      )}
    </>
  );
};

import { createContext, Dispatch, FC, useReducer } from "react";
import { RfidAction, RfidState } from "../interface/rfid";
import { initialState, reducer } from "../reducer/rfid.reducer";

const RfidContext = createContext<{
  state: RfidState;
  dispatch: Dispatch<RfidAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

export default RfidContext;

export const RfidProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <RfidContext.Provider value={{ state, dispatch }}>{children}</RfidContext.Provider>;
};

import {
  Box,
  chakra,
  CircularProgress,
  Flex,
  SimpleGrid,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  useColorModeValue,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { GiRolledCloth } from "react-icons/gi";
import { useGetAllQuery as useGetAllTypesQuery } from "../services/typesApi";
import { useGetAllQuery as useGetAllUserQuery } from "../services/employeeApi";
import { WarningIcon } from "@chakra-ui/icons";
import { ADMIN_ACCESS } from "../utils/roles";
import useRoles from "../hook/useRoles";
import { BsPerson } from "react-icons/bs";
import { useTranslation } from "react-i18next";

interface StatsCardProps {
  title: string;
  stat: string;
  icon: ReactNode;
  isLoading: boolean;
  isError: boolean;
}

function StatsCard(props: StatsCardProps) {
  const { title, stat, icon, isLoading, isError } = props;
  const borderColor = useColorModeValue("gray.800", "gray.500");
  return (
    <>
      <Stat
        px={{ base: 2, md: 4 }}
        py={"5"}
        shadow={"xl"}
        border={"1px solid"}
        borderColor={isError ? "red" : borderColor}
        rounded={"lg"}
      >
        <Flex justifyContent={"space-between"}>
          <Box pl={{ base: 2, md: 4 }}>
            <StatLabel fontWeight={"medium"} isTruncated>
              {title}
            </StatLabel>
            <StatNumber fontSize={"2xl"} fontWeight={"medium"}>
              {isLoading ? (
                <CircularProgress isIndeterminate color={"teal"} />
              ) : isError ? (
                <WarningIcon color={"red.500"} />
              ) : (
                stat
              )}
            </StatNumber>
            {isError && <StatHelpText color={"red"}>Wystąpił problem z pobraniem danych</StatHelpText>}
          </Box>
          <Box my={"auto"} color={useColorModeValue("gray.800", "gray.200")} alignContent={"center"}>
            {icon}
          </Box>
        </Flex>
      </Stat>
    </>
  );
}

export default function Home() {
  const { t } = useTranslation();
  const { userHasRoles } = useRoles();
  const { isLoading: isLoadingTypes, data: types = [], isError: isTypesError } = useGetAllTypesQuery();
  const { isLoading: isLoadingUsers, data: users = [], isError: isUsersError } = useGetAllUserQuery();
  return (
    <Box maxW="7xl" mx={"auto"} pt={5} px={{ base: 2, sm: 12, md: 17 }}>
      <chakra.h1 textAlign={"center"} fontSize={"4xl"} py={10} fontWeight={"bold"}>
        {t("Twórz firme z naszymi rozwiązaniami")}
      </chakra.h1>
      <SimpleGrid
        columns={{ base: 1, md: userHasRoles(ADMIN_ACCESS) ? 3 : 2 }}
        justifyContent={"center"}
        spacing={{ base: 5, lg: 8 }}
      >
        {userHasRoles(ADMIN_ACCESS) && (
          <StatsCard
            title={t("Użytkownicy")}
            stat={users.length.toString()}
            icon={<BsPerson size={"3em"} />}
            isLoading={isLoadingUsers}
            isError={isUsersError}
          />
        )}
        <StatsCard
          title={t("Typy materiałów")}
          stat={types.length.toString()}
          icon={<GiRolledCloth size={"3em"} />}
          isLoading={isLoadingTypes}
          isError={isTypesError}
        />
      </SimpleGrid>
    </Box>
  );
}

declare global {
  interface Window {
    _env_: any;
  }
}

// change with your own variables
type EnvType = {
  REACT_APP_API_URL: string;
  REACT_APP_KEYCLOAK_CLIENT_ID: string;
  REACT_APP_KEYCLOAK_REALM: string;
  REACT_APP_KEYCLOAK_URL: string;
};

export const env: EnvType = window._env_;
console.log("env", env);
export const API_URL = env.REACT_APP_API_URL;

export const ENDPOINTS = {
  get EMPLOYEE() {
    return `${API_URL}/employee`;
  },
  get TYPES() {
    return `${API_URL}/type`;
  },
  get STOCK() {
    return `${API_URL}/stock`;
  },
  get REPORT() {
    return `${API_URL}/report`;
  },
};

import { Cell } from "react-table";
import { Employee } from "../interface";
import { HStack } from "@chakra-ui/react";
import useConfirm from "../hook/confirm";
import { useDeleteByIdMutation, useUpdateEmployeeStatusMutation } from "../services/employeeApi";
import { IconButton } from "../components/IconButton";
import { DeleteIcon, RepeatIcon } from "@chakra-ui/icons";
import { AddEditEmployeeModal } from "./AddEditEmployeeModal";
import { errorToast, successToast } from "../utils/toast";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export const UserActions = (cell: Cell<Employee>) => {
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const [updateStatus, { isLoading: isStatusLoading }] = useUpdateEmployeeStatusMutation();
  const [removeDelete, { isLoading }] = useDeleteByIdMutation();

  const onUpdateStatus = useCallback(() => {
    confirm(t("Czy chcesz zmieniś status użytkownika?")).then((isConfirmed) => {
      if (isConfirmed) {
        updateStatus({
          id: cell.row.original.id,
          status: !cell.row.original.active,
        })
          .unwrap()
          .then(() => successToast(t("Status użytkownika został zmieniony")))
          .catch(() => errorToast(t("Wystąpił błąd podczas zmiany statusu użytkownika")));
      }
    });
  }, [cell, confirm, updateStatus]);

  const onDelete = useCallback(() => {
    confirm(t("Czy chcesz usunąć użytkownika?")).then((isConfirmed) => {
      if (isConfirmed) {
        removeDelete(cell.row.original.id)
          .unwrap()
          .then(() => successToast(t("Użytkownik został usunięty")))
          .catch(() => errorToast(t("Wystąpił błąd podczas usuwania użytkownika")));
      }
    });
  }, [confirm, cell, removeDelete]);

  return (
    <HStack spacing={3}>
      <IconButton
        testId={"change-user-status"}
        isLoading={isStatusLoading}
        icon={<RepeatIcon />}
        tooltip={t("Zmień status")}
        onClick={onUpdateStatus}
      />
      <AddEditEmployeeModal icon employee={cell.row.original} />
      <IconButton
        testId={"delete-user"}
        disabled={cell.row.original.deleted}
        isLoading={isLoading}
        icon={<DeleteIcon color={"red.500"} />}
        tooltip={t("Usuń")}
        onClick={onDelete}
      />
    </HStack>
  );
};

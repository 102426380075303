import { createStandaloneToast, ToastPositionWithLogical } from "@chakra-ui/react";

const toast = createStandaloneToast();

export const baseOptions = {
  duration: 3000,
  isClosable: true,
  position: "top" as ToastPositionWithLogical,
};

export const successOptions = {
  ...baseOptions,
  status: "success" as any,
  title: "Sukces",
};

export const errorOptions = {
  ...baseOptions,
  status: "error" as any,
  title: "Błąd",
};

export const infoOptions = {
  ...baseOptions,
  status: "info" as any,
  title: "Informacje",
};

export const successToast = (description: string) =>
  toast({
    ...successOptions,
    description,
  });

export const errorToast = (description: string) =>
  toast({
    ...errorOptions,
    description,
  });

export const infoToast = (description: string) =>
  toast({
    ...infoOptions,
    description,
  });

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from "../config";
import { Id, IStock, StockPost, StockPut, UpdateStockLocation, UpdateStockNote } from "../interface";
import { prepareHeaders, providesList } from "../utils/reudx";
import * as _ from "lodash";
import { IGetStockByQuery, InputGetStockByQuery } from "../interface/api";

const tagName = "Stock";

export const stockApi = createApi({
  reducerPath: "stockApi",
  baseQuery: fetchBaseQuery({
    baseUrl: ENDPOINTS.STOCK,
    prepareHeaders: prepareHeaders,
  }),
  tagTypes: [tagName],
  endpoints: (builder) => ({
    getAll: builder.query<IGetStockByQuery, Partial<InputGetStockByQuery>>({
      query: (body) => ({
        url: "/getStockByQuery",
        method: "POST",
        body: body,
      }),
      providesTags: (result) => providesList(result?.stocks ?? [], tagName),
    }),
    addStock: builder.mutation<IStock, StockPost & { employeeCode?: string }>({
      query: (body) => ({
        url: `/add`,
        method: "POST",
        body: _.pickBy(body, _.identity),
      }),
      invalidatesTags: [tagName],
    }),
    updateStock: builder.mutation<IStock, StockPut>({
      query: (body) => ({
        url: `/update`,
        method: "PUT",
        body: _.pickBy(body, _.identity),
      }),
      invalidatesTags: [tagName],
    }),
    updateNote: builder.mutation<IStock, UpdateStockNote>({
      query: (body) => ({
        url: `/updateNote`,
        method: "PUT",
        body: _.pickBy(body, _.identity),
      }),
      invalidatesTags: [tagName],
    }),
    updateLocation: builder.mutation<IStock, UpdateStockLocation>({
      query: (body) => ({
        url: `/updateLocation`,
        method: "PUT",
        body: _.pickBy(body, _.identity),
      }),
      invalidatesTags: [tagName],
    }),
    markAsRemoved: builder.mutation<void, { id: Id; code?: string }>({
      query: (body) => {
        if (!!body.code) {
          return {
            url: `/markAsRemoved/${body.code}/${body.id}`,
            method: "PUT",
          };
        } else {
          return {
            url: `/markAsRemoved/${body.id}`,
            method: "PUT",
          };
        }
      },
      invalidatesTags: [tagName],
    }),
    getStockById: builder.query<IStock, Id>({
      query: (id) => ({
        url: `/get/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [{ type: tagName, id: arg }],
    }),
  }),
});

export const {
  useGetAllQuery,
  useAddStockMutation,
  useUpdateStockMutation,
  useMarkAsRemovedMutation,
  useUpdateNoteMutation,
  useUpdateLocationMutation,
  useGetStockByIdQuery,
} = stockApi;

export const defaultStockQuery: Partial<InputGetStockByQuery> = {
  show: "ALL",
  sortDescending: false,
  maxResults: 50,
  offset: 0,
};

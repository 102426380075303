export const ROLES = {
  ADMIN: "ADMIN",
  EMPLOYEE: "EMPLOYEE",
};

export type Roles = keyof typeof ROLES;

export const FULL_ACCESS: Array<Roles> = [ROLES.ADMIN as Roles, ROLES.EMPLOYEE as Roles];

export const EMPLOYEE_ACCESS: Array<Roles> = [ROLES.EMPLOYEE as Roles];

export const ADMIN_ACCESS: Array<Roles> = [ROLES.ADMIN as Roles];

import axios from "axios";
import { API_URL } from "../config";

const client = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const setTokenHeader = (token: string) => {
  delete client.defaults.headers.common["Authorization"];
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export default client;

import { InputWrapper } from "../components/Input";
import { validationRules } from "../utils/reactHookForm";
import { useFormContext, UseFormReturn } from "react-hook-form";
import { InputProps } from "@chakra-ui/input/dist/declarations/src/input";
import { useTranslation } from "react-i18next";

type IInput = Partial<InputProps>;

const LocationInputBase = ({ register, formState: { errors }, isDisabled, isRequired }: UseFormReturn & IInput) => {
  const { t } = useTranslation();
  return (
    <InputWrapper
      data-cy={"location-input"}
      {...register("location", {
        required: isRequired ? validationRules.required : false,
        minLength: validationRules.minLength(3),
        maxLength: validationRules.maxLength(20),
      })}
      label={t("Lokalizacja")}
      errors={errors}
      isDisabled={isDisabled}
    />
  );
};

export const LocationInput = (props: IInput) => {
  const methods = useFormContext();

  return <LocationInputBase {...methods} {...props} />;
};

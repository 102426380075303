import { useFormContext, UseFormReturn } from "react-hook-form";
import { InputWrapper } from "../components/Input";
import { validationRules } from "../utils/reactHookForm";
import { InputProps } from "@chakra-ui/input/dist/declarations/src/input";
import { useTranslation } from "react-i18next";

type IInput = Partial<InputProps>;

const WeightInputBase = ({
  register,
  formState: { errors },
  isDisabled,
  isRequired,
  autoFocus,
}: UseFormReturn & IInput) => {
  const { t } = useTranslation();
  return (
    <InputWrapper
      data-cy={"weight-input"}
      autoFocus={autoFocus}
      type={"number"}
      step="0.01"
      {...register("weight", {
        required: isRequired ? validationRules.required : false,
        min: validationRules.min(0.001),
        valueAsNumber: true,
      })}
      label={t("Waga")}
      errors={errors}
      min={0}
      rightaddon={"kg"}
      isDisabled={isDisabled}
    />
  );
};

export const WeightInput = (props: IInput) => {
  const methods = useFormContext();

  return <WeightInputBase {...methods} {...props} />;
};

import { ListItem, Text } from "@chakra-ui/react";
import { ReactNode } from "react";

interface Props {
  title: string;
  value: string | number | ReactNode;
}

export const ListElement = ({ title, value }: Props) => {
  return (
    <ListItem>
      <Text as={"span"} fontWeight={"bold"}>
        {title}
      </Text>{" "}
      {value}
    </ListItem>
  );
};

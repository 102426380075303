export const showType = {
  ALL: "Wszystkie",
  ACTIVE: "Aktywne",
  REMOVED: "Usunięte",
};

export const numberSt = {
  equal: "Równe",
  lessOrEqual: "Mniejsze lub równe",
  moreOrEqual: "Większe lub równe",
  less: "Mniejsze",
  more: "Większe",
};

export const stringSt = {
  equal: "Równe",
  like: "Podobne do",
  like_begin: "Zaczyna się od",
  like_end: "Kończy się na",
  not_equal: "Nie równe",
};

import { FC } from "react";
import { Box, Center, Flex, Heading, Spinner, Text, VStack } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";

interface Prosp {
  isLoading: boolean;
  isError: boolean;
}

export const AsyncBox: FC<Prosp> = ({ children, isLoading, isError }) => {
  const { t } = useTranslation();
  if (isLoading) {
    return (
      <Center h="100px">
        <VStack>
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
          <Text fontSize="3xl" isTruncated>
            {t("ŁADOWANIE...")}
          </Text>
        </VStack>
      </Center>
    );
  }
  if (isError) {
    return (
      <Box textAlign="center" py={10} px={6}>
        <Box display="inline-block">
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bg={"red.500"}
            rounded={"50px"}
            w={"55px"}
            h={"55px"}
            textAlign="center"
          >
            <CloseIcon boxSize={"20px"} color={"white"} />
          </Flex>
        </Box>
        <Heading as="h2" size="xl" mt={6} mb={2}>
          {t("Wysąpił błąd")}
        </Heading>
        <Text color={"gray.500"}>
          {t("Nie udało się pobrać danych. Odśwież stronę i spróbuj ponownie lub skontaktuj się z administratorem.")}
        </Text>
      </Box>
    );
  }
  return <div>{children}</div>;
};

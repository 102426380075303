import { Id } from "../interface";
import { useGetByIdQuery } from "../services/typesApi";
import { Spinner } from "@chakra-ui/react";
import { memo } from "react";

interface Props {
  id: Id;
}

export const Type = memo(({ id }: Props) => {
  const { data, isLoading, isSuccess } = useGetByIdQuery(id);

  if (isLoading) return <Spinner />;
  else if (!!data && isSuccess) return <>{data.name}</>;
  else return <>{id}</>;
});

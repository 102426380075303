import { ActionMap } from "./index";

export interface ConfirmState {
  show: boolean;
  text: string;
}

export enum ConfirmTypes {
  SHOW_CONFIRM = "SHOW_CONFIRM",
  HIDE_CONFIRM = "HIDE_CONFIRM",
}

type ConfirmPayload = {
  [ConfirmTypes.SHOW_CONFIRM]: ConfirmState;
  [ConfirmTypes.HIDE_CONFIRM]: undefined;
};

export type ConfirmAction = ActionMap<ConfirmPayload>[keyof ActionMap<ConfirmPayload>];

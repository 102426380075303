import Keycloak from "keycloak-js";
import { env } from "../config";

const keycloak = Keycloak({
  url: env.REACT_APP_KEYCLOAK_URL,
  realm: env.REACT_APP_KEYCLOAK_REALM,
  clientId: env.REACT_APP_KEYCLOAK_CLIENT_ID,
});

export default keycloak;

import {
  Button,
  List,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { SubmitHandler, useForm } from "react-hook-form";
import { Id } from "../interface";
import { validationRules } from "../utils/reactHookForm";
import { InputWrapper } from "./Input";
import { useGetStockByIdQuery } from "../services/stockApi";
import { useCallback, useState } from "react";
import { ListElement } from "./List";
import { DateCell } from "./DateCell";
import { Type } from "./Type";
import { StockIdCell } from "./StockIdCell";
import { StocksActions } from "../container/StocksActions";
import { Peon } from "./Peon";
import { useTranslation } from "react-i18next";

interface StockModalProps {
  id: Id;
}

const Stock = ({ id }: StockModalProps) => {
  const { t } = useTranslation();
  const { data, isLoading, isFetching } = useGetStockByIdQuery(id);

  if (isLoading || isFetching) {
    return <div>Loading...</div>;
  } else if (!!data) {
    return (
      <List spacing={2}>
        <ListElement title={"ID:"} value={<StockIdCell id={data.id} />} />
        <ListElement title={t("Waga:")} value={data.weight} />
        <ListElement title={t("Wysokość:")} value={data.width} />
        <ListElement title={t("Lokalizacja:")} value={data.location} />
        <ListElement title={t("Typ:")} value={<Type id={data.typeId} />} />
        {!!data.note ? <ListElement title={t("Notatka:")} value={data.note} /> : null}
        <ListElement title={t("Dodano:")} value={<DateCell dateIso={data.added} />} />
        {!!data.addedBy ? (
          <ListElement title={t("Dodał:")} value={`${data.addedBy?.name} ${data.addedBy?.surname}`} />
        ) : null}
        {data.removed ? <ListElement title={t("Usunięto:")} value={<DateCell dateIso={data.removed} />} /> : null}
        {data.removedBy ? <ListElement title={t("Usunął:")} value={<Peon peon={data.removedBy} />} /> : null}
        <ListElement title={t("Akcje:")} value={<StocksActions stock={data} />} />
      </List>
    );
  } else {
    return null;
  }
};

interface BarcodeForm {
  barcode: string;
}

export const StockModal = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [id, setId] = useState<Id | undefined>(undefined);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<BarcodeForm>();
  const onSubmit: SubmitHandler<BarcodeForm> = async (values: BarcodeForm) => {
    let barcodeForm = values.barcode;
    let position = 0;
    for (let i = 0; i < barcodeForm.length; i++) {
      if (`${barcodeForm.charAt(i)}` === "0") position += 1;
      else break;
    }
    const newBarcode = barcodeForm.slice(position, barcodeForm.length);
    setId(newBarcode);
  };

  const onExit = useCallback(() => {
    reset();
    setId(undefined);
    onClose();
  }, [onClose, reset]);

  const onReset = useCallback(() => {
    reset();
    setId(undefined);
  }, [reset]);

  const form = (
    <InputWrapper
      autoFocus
      data-cy={"barcode-input"}
      type={"number"}
      {...register("barcode", {
        required: validationRules.required,
      })}
      label={t("Szukany kod:")}
      errors={errors}
      helpertext={t("Użyj skanera")}
    />
  );

  return (
    <>
      <Button colorScheme={"teal"} variant="outline" leftIcon={<SearchIcon />} onClick={onOpen}>
        {t("Znajdź stock na podstawie id")}
      </Button>
      <Modal onClose={onExit} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>{t("Szukaj stocka na podstawie id")}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>{!!id ? <Stock id={id} /> : form}</ModalBody>
            <ModalFooter>
              <Button onClick={onReset} mr={3}>
                {t("Reset")}
              </Button>
              <Button colorScheme={"teal"} type="submit">
                {t("Szukaj")}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

import { Cell } from "react-table";
import { Types } from "../interface";
import { HStack } from "@chakra-ui/react";
import useConfirm from "../hook/confirm";
import { useDeleteByIdMutation, useUpdateTypesStatusMutation } from "../services/typesApi";
import { IconButton } from "../components/IconButton";
import { DeleteIcon, RepeatIcon } from "@chakra-ui/icons";
import { AddEditTypes } from "./AddEditTypes";
import { useCallback } from "react";
import { errorToast, successToast } from "../utils/toast";
import { useTranslation } from "react-i18next";

export const TypesActions = (cell: Cell<Types>) => {
  const { t } = useTranslation();
  const { confirm } = useConfirm();

  const [removeDelete, { isLoading }] = useDeleteByIdMutation();
  const [updateStatus, { isLoading: isStatusLoading }] = useUpdateTypesStatusMutation();

  const changeStatus = useCallback(() => {
    confirm(t("Czy chcesz zmieniś status użytkownika?")).then((isConfirmed) => {
      if (isConfirmed) {
        updateStatus({
          id: cell.row.original.id,
          status: !cell.row.original.active,
        })
          .unwrap()
          .then(() => successToast(t("Status foli został zmieniony")))
          .catch(() => errorToast(t("Wystąpił błąd podczas zmiany statusu foli")));
      }
    });
  }, [cell, confirm, updateStatus]);

  const remove = useCallback(() => {
    confirm(t("Czy chcesz usunąć ten typ?")).then((isConfirmed) => {
      if (isConfirmed) {
        removeDelete(cell.row.original.id)
          .unwrap()
          .then(() => successToast(t("Użytkownik został usunięty")))
          .catch(() => errorToast(t("Wystąpił błąd podczas usuwania foli")));
      }
    });
  }, [cell, confirm, removeDelete]);

  return (
    <HStack spacing={3}>
      <IconButton
        testId={"change-type-status"}
        isLoading={isStatusLoading}
        icon={<RepeatIcon />}
        tooltip={t("Zmień status")}
        onClick={changeStatus}
      />
      <AddEditTypes icon types={cell.row.original} />
      <IconButton
        testId={"remove-type"}
        isLoading={isLoading}
        icon={<DeleteIcon color={"red.500"} />}
        tooltip={t("Usuń")}
        onClick={remove}
      />
    </HStack>
  );
};

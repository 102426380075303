import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
} from "@chakra-ui/react";
import { FieldErrors } from "react-hook-form";
import { InputProps } from "@chakra-ui/input/dist/declarations/src/input";
import { forwardRef, LegacyRef } from "react";

type Ref = LegacyRef<HTMLInputElement> | undefined;

interface IInput {
  name: string;
  label: string;
  errors?: FieldErrors<any>;
  helpertext?: string;
  rightaddon?: string;
}

export type InputPropsBase = InputProps & IInput;

export const InputWrapper = forwardRef((props: InputPropsBase, ref: Ref) => {
  return (
    <FormControl isInvalid={props.errors[props.name]}>
      <FormLabel htmlFor={props.name}>{props.label}</FormLabel>
      <InputGroup>
        <Input {...props} ref={ref} />
        {props.rightaddon && <InputRightAddon children={props.rightaddon} />}
      </InputGroup>
      {props.helpertext && <FormHelperText>{props.helpertext}</FormHelperText>}
      <FormErrorMessage>{props.errors[props.name] && props.errors[props.name].message}</FormErrorMessage>
    </FormControl>
  );
});

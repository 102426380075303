import { useKeycloak } from "@react-keycloak/web";
import { ROLES, Roles } from "../utils/roles";

export default function useRoles() {
  const { keycloak } = useKeycloak();

  const isAdmin = keycloak.hasRealmRole(ROLES.ADMIN);
  const isPeon = keycloak.hasRealmRole(ROLES.EMPLOYEE);

  const userHasRoles = (roles: Array<Roles>) => {
    return roles.some((role) => keycloak.hasRealmRole(role));
  };

  return {
    isAdmin: isAdmin,
    isUser: isPeon,
    userHasRoles,
  };
}

import { useKeycloak } from "@react-keycloak/web";
import { FC } from "react";
import { Navigate, useLocation } from "react-router-dom";

export const PrivateRoute: FC<any> = ({ children }) => {
  const { keycloak } = useKeycloak();
  const isLoggedIn = keycloak.authenticated;
  const location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

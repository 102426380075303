import { Action, configureStore, ThunkDispatch } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { employeeApi } from "../services/employeeApi";
import { typesApi } from "../services/typesApi";
import { stockApi } from "../services/stockApi";
import logger from "redux-logger";
import thunkMiddleware from "redux-thunk";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import authReducer from "../slice/authSlice";
import filtersReducer from "../slice/filtersSlice";

const middlewares = [employeeApi.middleware, typesApi.middleware, stockApi.middleware, thunkMiddleware];

if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}

export const store = configureStore({
  reducer: {
    auth: authReducer,
    stockQuery: filtersReducer,
    // Add the generated reducer as a specific top-level slice
    [employeeApi.reducerPath]: employeeApi.reducer,
    [typesApi.reducerPath]: typesApi.reducer,
    [stockApi.reducerPath]: stockApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const dispatch: AppDispatch = store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;
// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

import { useGetAllQuery } from "../services/typesApi";
import { useMemo } from "react";
import { BooleanCell } from "../components/BoolenCell";
import { DataTable } from "../components/Table";
import { AsyncBox } from "../components/AsyncBox";
import { TypesActions } from "../container/TypesActions";
import { AddEditTypes } from "../container/AddEditTypes";
import { useTranslation } from "react-i18next";

export const TypesTable = () => {
  const { t } = useTranslation();
  const { isLoading, data = [], isError } = useGetAllQuery();

  const memoData = useMemo(() => data, [data]);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: t("Nazwa"),
        accessor: "name",
      },
      {
        Header: t("Gramatura"),
        accessor: "grammage",
      },
      {
        Header: t("Aktywny"),
        accessor: "active",
        Cell: BooleanCell,
      },
      {
        Header: t("Akcje"),
        accessor: "actions",
        Cell: TypesActions,
      },
    ],
    [t]
  );

  return (
    <AsyncBox isError={isError} isLoading={isLoading}>
      <AddEditTypes button />
      <DataTable data={memoData} columns={columns} />
    </AsyncBox>
  );
};

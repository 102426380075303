export const downloadCsvFile = (data: string) => {
  let csvContent = "data:text/csv;charset=utf-8,";

  csvContent += data;
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "raport.csv");
  document.body.appendChild(link); // Required for FF

  link.click(); // This will download the
};

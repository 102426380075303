import { IStock, StockPost } from "../interface";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { AddEditElement } from "../components/AddEditElement";
import {
  useAddStockMutation,
  useUpdateLocationMutation,
  useUpdateNoteMutation,
  useUpdateStockMutation,
} from "../services/stockApi";
import { useGetAllQuery } from "../services/typesApi";
import {
  ElementRef,
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { WeightInput } from "../form/WeightInput";
import { WidthInput } from "../form/WidthInput";
import { LocationInput } from "../form/LocationInput";
import { NoteInput } from "../form/NoteInput";
import { TypeInput } from "../form/TypeInput";
import useRoles from "../hook/useRoles";
import { ADMIN_ACCESS } from "../utils/roles";
import useRfid from "../hook/useRfid";
import { successToast } from "../utils/toast";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../utils/store";
import { reset } from "../slice/filtersSlice";

export interface ForwardRef {
  editNote: () => void;
  editLocation: () => void;
}

interface Props {
  icon?: boolean;
  button?: boolean;
  stock?: IStock;
}

type ActiveFields = {
  name: boolean;
  weight: boolean;
  width: boolean;
  location: boolean;
  typeId: boolean;
  note: boolean;
};

const AddEditStockModalBase: ForwardRefRenderFunction<ForwardRef, Props> = (
  { stock, icon = false, button = false },
  ref
) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { userHasRoles } = useRoles();
  const { getRfid } = useRfid();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [addStock, { isLoading }] = useAddStockMutation();

  const [updateStock, { isLoading: isUpdateLoading }] = useUpdateStockMutation();
  const [updateNote, { isLoading: isUpdateNoteLoading }] = useUpdateNoteMutation();
  const [updateLocation, { isLoading: isUpdateLocationLoading }] = useUpdateLocationMutation();

  const [activeFields, setActiveFields] = useState<ActiveFields>({
    name: true,
    weight: true,
    width: true,
    location: true,
    typeId: true,
    note: true,
  });
  const [editNote, setEditNote] = useState(false);
  const [editLocation, setEditLocation] = useState(false);

  const { data = [] } = useGetAllQuery();

  const memoStock = useMemo(() => stock, [stock]);
  const methods = useForm<StockPost>({
    defaultValues: stock
      ? memoStock
      : {
          location: "",
          typeId: "",
          note: "",
        },
  });

  useEffect(() => {
    methods.reset(stock);
  }, [methods, stock]);

  const onCloseModal = useCallback(() => {
    methods.reset();
    onClose();
    setActiveFields({
      name: true,
      weight: true,
      width: true,
      location: true,
      typeId: true,
      note: true,
    });
    setEditNote(false);
    setEditLocation(false);
    dispatch(reset());
  }, [dispatch, methods, onClose]);

  const onSubmit: SubmitHandler<StockPost> = async (values: StockPost) => {
    if (stock && editLocation) {
      await updateLocation({ id: stock.id, location: values.location })
        .unwrap()
        .then(() => {
          successToast(t("Zaktualizowano lokalizację"));
          onCloseModal();
        })
        .catch(() => successToast(t("Nie udało się zaktualizować lokalizacji")));
    } else if (stock && editNote) {
      await updateNote({ id: stock.id, note: values.note })
        .unwrap()
        .then(() => {
          successToast(t("Zaktualizowano notatkę"));
          onCloseModal();
        })
        .catch(() => successToast(t("Nie udało się zaktualizować notatki")));
    } else if (stock) {
      await updateStock({ id: stock.id, ...values })
        .unwrap()
        .then(() => {
          successToast(t("Zaktualizowano stock"));
          onCloseModal();
        })
        .catch(() => successToast(t("Nie udało się zaktualizować stock")));
    } else {
      if (userHasRoles(ADMIN_ACCESS)) {
        addStock(values)
          .unwrap()
          .then(() => {
            successToast(t("Dodano stock"));
            onCloseModal();
          })
          .catch(() => successToast(t("Nie udało się dodać nowego stock")));
      } else {
        getRfid(t("Aby dodać nowy element, skorzystaj ze swojego tokena")).then((rfid) => {
          addStock({ ...values, employeeCode: rfid })
            .unwrap()
            .then(() => {
              successToast(t("Dodano stock"));
              onCloseModal();
            })
            .catch(() => successToast(t("Nie udało się dodać nowego stock")));
        });
      }
    }
    onCloseModal();
  };

  const editOnlyNote = useCallback(() => {
    setActiveFields({
      name: false,
      weight: false,
      width: false,
      location: false,
      typeId: false,
      note: true,
    });
    setEditNote(true);
    onOpen();
  }, [onOpen]);

  const editOnlyLocation = useCallback(() => {
    setActiveFields({
      name: false,
      weight: false,
      width: false,
      location: true,
      typeId: false,
      note: false,
    });
    setEditLocation(true);
    onOpen();
  }, [onOpen]);

  useImperativeHandle(
    ref,
    () => ({
      editNote: editOnlyNote,
      editLocation: editOnlyLocation,
    }),
    [editOnlyNote, editOnlyLocation]
  );
  const defaultValue = stock && data ? data.find((item) => item.id === stock?.typeId)?.name ?? "" : undefined;
  return (
    <>
      <AddEditElement
        testIdButton={"add-stock-button"}
        testIdIcon={"add-stock-icon"}
        onOpen={onOpen}
        icon={userHasRoles(ADMIN_ACCESS) && icon}
        buttonLabel={button ? t("Dodaj nowy stock") : undefined}
      />

      <Modal isOpen={isOpen} onClose={onCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <ModalHeader>{stock ? t("Edytuj stock") : t("Dodaj stock")}</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <WeightInput autoFocus isRequired isDisabled={!activeFields.weight} />
                <WidthInput isRequired isDisabled={!activeFields.width} />
                <TypeInput isRequired defaultValue={defaultValue} isDisabled={!activeFields.typeId} />
                <LocationInput isRequired isDisabled={!activeFields.location} />
                <NoteInput isDisabled={!activeFields.note} />
              </ModalBody>
              <ModalFooter>
                <Button
                  data-cy={"send-stock-form"}
                  colorScheme="teal"
                  mr={3}
                  type="submit"
                  isLoading={isLoading || isUpdateLoading || isUpdateNoteLoading || isUpdateLocationLoading}
                >
                  {t("Zapisz")}
                </Button>
                <Button data-cy="reset-stock-form" onClick={onCloseModal}>
                  {t("Anuluj")}
                </Button>
              </ModalFooter>
            </form>
          </FormProvider>
        </ModalContent>
      </Modal>
    </>
  );
};

export type AddEditRef = ElementRef<typeof AddEditStockModal>;
export const AddEditStockModal = forwardRef(AddEditStockModalBase);

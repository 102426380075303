import { useCallback, useRef } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import useRfid from "../hook/useRfid";
import { InputWrapper } from "./Input";
import { validationRules } from "../utils/reactHookForm";
import { SubmitHandler, useForm } from "react-hook-form";
import { useGetEmployeeByRfidMutation } from "../services/employeeApi";
import { Employee } from "../interface";
import { errorToast } from "../utils/toast";
import { useTranslation } from "react-i18next";

interface RfidForm {
  rfid: string;
}

export const RfidModal = () => {
  const { t } = useTranslation();
  const { state, onCancel, onOk, onError, onClose } = useRfid();
  const cancelRef = useRef(null);
  const [getUserByRfid, { isLoading }] = useGetEmployeeByRfidMutation();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<RfidForm>();

  const onSubmit: SubmitHandler<RfidForm> = async (values: RfidForm) => {
    getUserByRfid({ rfid: values.rfid })
      .unwrap()
      .then((res: Employee) => {
        onOk(res.code);
        reset();
        onClose();
      })
      .catch((err) => {
        if (err.status === 404) {
          errorToast(t("Taki token nie istnieje w Twojej bazie"));
        }
        onError(err);
      });
  };

  const onExit = useCallback(() => {
    reset();
    onCancel();
  }, [onCancel, reset]);

  return (
    <AlertDialog isOpen={state.show} leastDestructiveRef={cancelRef} onClose={onExit}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {state.title}
            </AlertDialogHeader>
            <AlertDialogBody>
              {state.description}
              <InputWrapper
                autoFocus
                data-cy={"rfid-input"}
                type={"number"}
                {...register("rfid", {
                  required: validationRules.required,
                  minLength: validationRules.minLength(10),
                  maxLength: validationRules.maxLength(10),
                })}
                label={t("Twoje RFID:")}
                errors={errors}
                helpertext={t("Użyj czytnika RFID")}
              />
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button data-cy={"cancel"} type={"reset"} onClick={onExit}>
                {t("Anuluj")}
              </Button>
              <Button data-cy={"confirm"} type={"submit"} colorScheme="teal" ml={3} isLoading={isLoading}>
                {t("Wyślij")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </form>
    </AlertDialog>
  );
};
